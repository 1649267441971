import { connect } from 'react-redux';

import Ledger from '../components/Ledger/Ledger';
import {
  getLedgerItems,
  getLedgerSummary,
  shouldDisplayBundleTerms,
  shouldDisplayChangePlan,
  shouldDisplayTermsLink,
  getLedgerTitleText,
  getDisplayPaymentFields,
} from '../selectors/ledger';
import { hasAssignment } from '../selectors/config';
import { isFeatureEnabled } from '../selectors/featureFlag';
import { getPlanOptions } from '../selectors/plans';
import { getIneligibleErrorText } from '../selectors/siteconfig';
import { getSelectedPlan } from '../selectors/user';
import { getIsIneligiblePromotion } from '../selectors/warning';
import { ITEM_TYPES as types } from '../constants/ledger';
import { LEDGER_UI_ACTIONS as ledgerActionCreators } from '../actions/ledgerActions';
import { CAT_357, CAT_90, WEB_24169 } from '../constants/experiments';
import { isStudentPlan } from '../selectors/plan';

const mapStateToProps = state => ({
  items: getLedgerItems(state),
  summary: getLedgerSummary(state),
  ineligibleErrorText: getIneligibleErrorText(state),
  displayBundleTerms: shouldDisplayBundleTerms(state),
  displayChangePlan: shouldDisplayChangePlan(state),
  ledgerTitle: getLedgerTitleText(state),
  promoTreatmentId: (getDisplayPaymentFields(state) && CAT_90.TREATMENT1) || '',
  isAdsPreferenceToggleTreatment: hasAssignment(state, WEB_24169.TREATMENT1),
  isAdsPreferenceCTATreatment: hasAssignment(state, WEB_24169.TREATMENT2),
  isPrepaidFeatureOn: isFeatureEnabled(state, 'prepaid'),
  isPrepaidOnBillingTreatment: hasAssignment(state, CAT_357.PREPAID_ON_BILLING),
  planOptions: getPlanOptions(state, getSelectedPlan(state)),
  isIneligiblePromotion: getIsIneligiblePromotion(state),
  isStudentPlan: isStudentPlan(getSelectedPlan(state)),
});

const mergeProps = (
  {
    items,
    displayBundleTerms,
    displayChangePlan,
    isPrepaidFeatureOn,
    isPrepaidOnBillingTreatment,
    planOptions,
    isAdsPreferenceCTATreatment,
    isAdsPreferenceToggleTreatment,
    ...stateProps
  },
  { showLedgerTermsModal, changePlan, deselectAddon, deselectAddonBundle }
) => {
  let adsPreferenceBillingTreatment = '';

  if (isAdsPreferenceToggleTreatment) {
    adsPreferenceBillingTreatment = WEB_24169.TREATMENT1;
  } else if (isAdsPreferenceCTATreatment) {
    adsPreferenceBillingTreatment = WEB_24169.TREATMENT2;
  }
  return {
    // Add actions to ledger line items
    items: items.map(
      ({ legalActionText, legalBody, name, type, id, ...itemProps }) => {
        const actions = [];

        // Add terms link
        if (
          shouldDisplayTermsLink(
            legalActionText,
            legalBody,
            type,
            displayChangePlan,
            displayBundleTerms
          )
        ) {
          actions.push({
            text: legalActionText,
            action: () => showLedgerTermsModal(name, legalBody),
          });
        }

        // Add type specific actions
        if (type === types.BASE_BUNDLE) {
          actions.push({
            text: 'Change Plan',
            shouldShow: displayChangePlan,
            action: () => changePlan(),
          });
        } else if (type === types.ADDON_BUNDLE) {
          actions.push({
            text: 'Remove',
            action: () => deselectAddonBundle(id),
          });
        } else if (type === types.ADDON) {
          actions.push({
            text: 'Remove',
            action: () => deselectAddon(id),
          });
        }

        return {
          ...itemProps,
          id,
          type,
          name,
          actions,
        };
      }
    ),
    shouldDisplayPlanOptions:
      isPrepaidFeatureOn &&
      isPrepaidOnBillingTreatment &&
      planOptions.length > 1,
    adsPreferenceBillingTreatment,
    ...stateProps,
  };
};

export default connect(
  mapStateToProps,
  ledgerActionCreators,
  mergeProps
)(Ledger);
