import React from 'react';
import first from 'lodash/first';
import get from 'lodash/get';

import env from '../../../config/env';
import {
  SASH,
  NOAH,
  LIVE,
  PSEUDO_2P,
  ADS_HULU_DISNEY_ESPN,
  ADS_LIVE_DISNEY_ESPN,
  NOADS_HULU_DISNEY_ESPN,
  NOADS_LIVE_DISNEY_ESPN,
  DUO_BASIC,
  DUO_PREMIUM,
} from '../constants/plans';

/**
 * Prepends Hulu's site root URL based on the current environment
 * @param path {String} Path to prepend the URL to
 */
export const siteUrlPrepended = path => {
  const { site } = env.url;

  return `${site}${path}`;
};

/**
 * Check if the plan matches any of the bundles to display a node that includes
 * the names for both desktop and mobile.
 * Otherwise, return the planName as plain text.
 *
 * @param {*} planIdentifier The type of plan
 * @param {*} planName Plan name used for desktop received from site-config
 * @param {*} planNameLong Plan name used for mobile received from site-config
 * @returns {Node|String}
 */
export const getName = (planIdentifier, planName, planNameLong) => {
  switch (planIdentifier) {
    case ADS_HULU_DISNEY_ESPN:
    case ADS_LIVE_DISNEY_ESPN:
    case NOADS_HULU_DISNEY_ESPN:
    case NOADS_LIVE_DISNEY_ESPN:
    case PSEUDO_2P:
    case DUO_BASIC:
    case DUO_PREMIUM:
      return (
        <>
          <span className="plan__title--desktop">{planName}</span>
          <span className="plan__title--mobile">{planNameLong}</span>
        </>
      );
    default:
      return planName;
  }
};

/**
 * Check the type of plan to display the description with
 * or without a short description that includes the price.
 * Otherwise, return the planDescription as plain text.
 *
 * @param {String} planIdentifier The type of plan
 * @param {String} planDescription The long description of the plan
 * @param {String} shortDescription The short description that includes the price
 * @returns {Node|String}
 */
export const getDescription = (
  planIdentifier,
  planDescription,
  shortDescription
) => {
  switch (planIdentifier) {
    case SASH:
    case NOAH:
    case LIVE:
      return <p className="plan__description--long">{planDescription}</p>;
    case ADS_HULU_DISNEY_ESPN:
    case ADS_LIVE_DISNEY_ESPN:
    case DUO_BASIC:
    case DUO_PREMIUM:
    case NOADS_HULU_DISNEY_ESPN:
    case NOADS_LIVE_DISNEY_ESPN:
    case PSEUDO_2P:
      return (
        <>
          <p className="plan__description--short">{shortDescription}</p>
          <p className="plan__description--long">{planDescription}</p>
        </>
      );
    default:
      return planDescription;
  }
};

/**
 * For HULU-14131 Value Props as Bullets Experiment
 * Check the type of plan to display the bulleted description with
 * or without a short description that includes the price.
 *
 * If valuePropBullets is empty, return undefined, then the default description prop
 * will be used within ValuePropsBulletsPlan component.
 *
 * @param {String} planIdentifier The type of plan
 * @param {Array} valuePropBullets List of value props to show as bullets
 * @param {String} shortDescription The short description that includes the price
 * @returns {JSXElement|undefined}
 */
export const getBulletedDescription = (
  planIdentifier,
  valuePropBullets,
  shortDescription
) => {
  if (valuePropBullets.length === 0) {
    return undefined;
  }

  const bulletedDescription = (
    <ul>
      {valuePropBullets.map(
        (bullet, index) =>
          bullet && (
            <li className="check" key={index}>
              {bullet}
            </li>
          )
      )}
    </ul>
  );

  switch (planIdentifier) {
    case SASH:
    case NOAH:
      return (
        <div className="plan__description--long plan__description--bullets">
          {bulletedDescription}
        </div>
      );
    case ADS_HULU_DISNEY_ESPN:
    case ADS_LIVE_DISNEY_ESPN:
    case NOADS_HULU_DISNEY_ESPN:
    case NOADS_LIVE_DISNEY_ESPN:
    case PSEUDO_2P:
      return (
        <>
          <p className="plan__description--short">{shortDescription}</p>
          <div className="plan__description--long plan__description--bullets">
            {bulletedDescription}
          </div>
        </>
      );
    default:
      return undefined;
  }
};

/**
 * Takes in plan.displayPricing and the plan.displayDiscount values from upstream,
 * returns discounted price with frequency if it exists,
 * otherwise returns the normal price with frequency.
 * @param {Array} planPricing An array that includes the price as a string
 * @param {Object} planDiscount An object that includes the discount
 * @return {string|*} Price with frequency as a string
 */
const getPriceWithFrequency = (planPricing, planDiscount) => {
  return get(planDiscount, 'discountWithFrequency', first(planPricing));
};

/**
 * Get the amount and period from the planPricing array
 *
 * @param {Array} planPricing An array that includes the price as a string
 * @param {Object} planDiscount An object that includes the discount
 * information associated to the price.
 * @returns {Node} Returns a node with the amount and period from the planPricing
 */
export const getPrice = (planPricing, planDiscount) => {
  const pricingText = getPriceWithFrequency(planPricing, planDiscount);
  const [amount, period] = pricingText.split('/');

  return (
    <>
      <span className="plan__price-amount">{amount}</span>
      <span className="plan__price-period">/ {period}</span>
    </>
  );
};

/**
 * Get the plan description with price to be used on mobile plan select.
 * Show plan discount price if it exists, otherwise show displayPricing.
 * @param longName Long plan name
 * @param plan The plan object from upstream
 * @param showLiveTvTrial {boolean} If the LiveTV Trial FF is on and plan is SASH Bundle
 * @param liveTvTrialDiscountDescription {text} LiveTV Trial Price Description copy
 * @return {string} The plan description with price
 */
export const getDescriptionWithPrice = (
  longName,
  plan,
  showLiveTvTrial,
  liveTvTrialDiscountDescription
) => {
  const pricingText = getPriceWithFrequency(
    plan.displayPricing,
    plan.displayDiscount
  );

  if (showLiveTvTrial) {
    return `${pricingText} ${liveTvTrialDiscountDescription}`;
  }

  return `Get ${longName} for ${pricingText}.`;
};

/**
 * Check if the plan matches any of the bundles to display the planNameLong
 * when the modal is opened
 *
 * @param {*} planIdentifier The type of plan
 * @param {*} planName Plan name used for desktop received from site-config
 * @param {*} planNameLong Plan name used for mobile received from site-config
 * @returns {Node|String}
 */
export const getModalTitle = (planIdentifier, planName, planNameLong) => {
  switch (planIdentifier) {
    case NOADS_HULU_DISNEY_ESPN:
    case NOADS_LIVE_DISNEY_ESPN:
    case ADS_HULU_DISNEY_ESPN:
    case ADS_LIVE_DISNEY_ESPN:
    case PSEUDO_2P:
      return planNameLong;
    default:
      return planName;
  }
};
