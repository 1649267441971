import { connect } from 'react-redux';
import first from 'lodash/first';
import { datadogRum } from '@datadog/browser-rum';
import {
  shouldSkipPlanSelect,
  shouldShowPlanSelectMessage,
  updateSelectedPlan,
  handlePlanTabSelect,
  handleBannerTermsClicked,
  handleBannerPlanSelected,
  refetchConfig,
  trackUserInteractionEvent,
} from '../actions/planSelectActions';
import { routeReplace, routePush } from '../actions/routingActions';
import { triggerReload } from '../actions/bootstrapActions';
import * as routes from '../constants/routes';
import * as MetricsEvents from '../metrics/metricsEvents';
import track from '../metrics/track';
import PlanSelectPage from '../pages/PlanSelectPage';
import { getPlanSelectDisclaimer } from '../selectors/dynamicMessaging';
import { getPlanCodeMessage, hasAssignment } from '../selectors/config';
import {
  getFromParam,
  getIsLiveOnlyFlow,
  getIsPartnerFlow,
  getStartPathname,
} from '../selectors/flow';
import {
  getFilteredPlans,
  getDefaultPlan,
  getShouldSkipPlanSelect,
  getBundlesForPlan,
  getHasUpsellPage,
  getPlanOptions,
  getPlanTabIndex,
  getShowBundleTab,
  getBannerOffer,
  getShow3UpView,
} from '../selectors/plans';
import { getShouldLogin } from '../selectors/login';
import { getIsBundleChecked } from '../selectors/user';
import { isFeatureEnabled } from '../selectors/featureFlag';
import * as MetricsUtils from '../utils/metricsUtils';
import withWindowSize from '../utils/withWindowSize';
import { CAT_357 } from '../constants/experiments';
import { PSEUDO_2P } from '../constants/plans';
import { replaceLocationQuery } from '../actions/urlActions';

export default track(
  withWindowSize(
    connect(
      (state, ownProps) => {
        const filteredPlans = getFilteredPlans(state, ownProps);
        const planTabIndex = getPlanTabIndex(state);
        const showBundleTab = getShowBundleTab(state);
        const {
          windowSize: { breakpoint: windowSizeBreakpoint },
        } = ownProps;

        return {
          plans: filteredPlans,
          planTabIndex,
          shouldLogin: getShouldLogin(state),
          shouldSkip: getShouldSkipPlanSelect(state),
          planMessage: getPlanCodeMessage(state),
          disclaimer: getPlanSelectDisclaimer(state),
          defaultPlan: getDefaultPlan(state),
          startPathname: getStartPathname(state),
          show3UpView: getShow3UpView(state),
          isPartnerFlow: getIsPartnerFlow(state),
          hasUpsellPage: getHasUpsellPage(state),
          isBundleChecked: plan => getIsBundleChecked(state, plan),
          findBundle: plan => getBundlesForPlan(state, plan)[0] || plan,
          isLiveOnly: getIsLiveOnlyFlow(state),
          findPlanOptions: plan => getPlanOptions(state, plan),
          isInPlanOptionTreatment: hasAssignment(
            state,
            CAT_357.PLAN_OPTION_PAGE
          ),
          isPrepaidFeatureOn: isFeatureEnabled(state, 'prepaid'),
          showBundleTab,
          isPriceIncreaseEnabled: isFeatureEnabled(state, 'mint'),
          bannerOffer: getBannerOffer(state, {
            filteredPlans,
            planTabIndex,
            showBundleTab,
            windowSizeBreakpoint,
          }),
          fromParam: getFromParam(state),
        };
      },
      dispatch => ({
        dispatch,
      }),
      (stateProps, dispatchProps, ownProps) => {
        const { dispatch } = dispatchProps;
        const {
          plans,
          planMessage,
          shouldLogin,
          shouldSkip,
          startPathname,
          show3UpView,
          isPartnerFlow,
          hasUpsellPage,
          isBundleChecked,
          findBundle,
          findPlanOptions,
          isInPlanOptionTreatment,
          isPrepaidFeatureOn,
          fromParam,
          ...otherProps
        } = stateProps;
        const {
          windowSize: { breakpoint: windowSizeBreakpoint },
        } = ownProps;

        return {
          shouldSkip,
          plans,
          planMessage,
          windowSizeBreakpoint,
          show3UpView,
          ...otherProps,
          onPlanSelected: (plan, isLiveTvLinkSelected = false) => {
            const { id, identifier } = plan;
            datadogRum.addAction('SELECT_PLAN', {
              data: {
                id,
                identifier,
              },
            });
            if (identifier === PSEUDO_2P) {
              dispatch(replaceLocationQuery({ promo_id: '18146' }));
              return dispatch(refetchConfig());
            }
            if (isLiveTvLinkSelected) {
              const liveTvLinkOptions = {
                action_specifier: 'driver_click:web',
                curr_page_uri: 'app:signup:plan_select',
                element_specifier: 'plan_select:hulu_noah_live_tv_link',
                interaction_type: 'click',
              };
              dispatch(trackUserInteractionEvent(liveTvLinkOptions));
            }

            dispatch(
              updateSelectedPlan(
                isBundleChecked(plan) ? findBundle(plan) : plan
              )
            );

            if (hasUpsellPage && findBundle(plan).id !== id) {
              return dispatch(routePush(routes.BUNDLE_SELECT));
            }

            if (shouldLogin) {
              return dispatch(routePush(routes.LOGIN));
            }

            if (
              isPrepaidFeatureOn &&
              isInPlanOptionTreatment &&
              findPlanOptions(plan).length > 1
            ) {
              return dispatch(routePush(routes.PLAN_OPTIONS));
            }

            return dispatch(routePush(routes.ACCOUNT_INFO));
          },
          onLoaded: () => {
            // Get into plan selection without plans available?
            // Go back to loading screen for non-partner flows!
            // Go to landing page for partner flow.
            if (!plans.length) {
              if (isPartnerFlow) {
                dispatch(routeReplace(startPathname));
              } else {
                dispatch(triggerReload());
              }
              return;
            }

            if (shouldSkip) {
              dispatch(shouldSkipPlanSelect(true));
              dispatch(updateSelectedPlan(first(plans)));

              if (shouldLogin) {
                dispatch(routeReplace(routes.LOGIN));
              } else if (
                isPrepaidFeatureOn &&
                isInPlanOptionTreatment &&
                findPlanOptions(first(plans)).length > 1
              ) {
                dispatch(routeReplace(routes.PLAN_OPTIONS));
              } else {
                dispatch(routeReplace(routes.ACCOUNT_INFO));
              }
            } else if (!fromParam) {
              ownProps.trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
                title: 'SUF - Plan Select',
                pageId: MetricsUtils.getPlanSelectPageId(plans),
              });
            }

            const showPlanSelectMessage = !!(
              planMessage &&
              planMessage.header &&
              planMessage.subheader
            );
            dispatch(shouldShowPlanSelectMessage(showPlanSelectMessage));
          },
          // Trigger an action to change planTabIndex on state and update plans props
          onTabSelect: index => dispatch(handlePlanTabSelect(index)),
          onBannerTermsClicked: bannerProgramId =>
            dispatch(handleBannerTermsClicked(bannerProgramId)),
          onBannerPlanSelected: bannerProgramId =>
            dispatch(handleBannerPlanSelected(bannerProgramId)),
        };
      }
    )(PlanSelectPage)
  )
);
