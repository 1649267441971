import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Badge from './Badge';
import CheckBoxField from './CheckBoxField';
import Panel from './Panel';
import PlanDisclaimer, { PlanDisclaimerPropTypes } from './PlanDisclaimer';
import {
  ADS_LIVE_DISNEY_ESPN,
  DUO_BASIC,
  DUO_PREMIUM,
  PSEUDO_2P,
  SASH,
} from '../constants/plans';
import { isSashLiveDisneyBundle } from '../selectors/plan';
import { sanitize } from '../utils/checkUp';
import { getPrice } from '../utils/planUtils';
import StudentDiscountLink from './StudentDiscountLink';
import LiveTvLink from './LiveTvLink';

require('../styles/plan.scss');

const Plan = ({
  plan,
  onPlanClick,
  onLinkPlanClick,
  planClass,
  negativePlanFeatureList,
  positivePlanFeatureList,
  buttonClass,
  isLiveOnly,
  isBundleOffer,
  isPriceZero,
  isLivePriority,
  hasBundles,
  hideTitle,
  shouldShowDetails,
  isInteractive,
  carouselActiveKey,
  hasTrial,
  planAltDisplayTrial,
  planDisplayTrial,
  planDisplayDiscount,
  showFlashSalePlan,
  badgeDescription,
  mobileBadgeDescription,
  badgeType,
  disclaimerProps,
  description,
  name,
  planDiscountDescription,
  isDisneySuperBundle,
  isHuluFreeTrialsEnabled,
}) => {
  const bundleLogoColorURL = '/static/images/disney-hulu-espn-bundle-logo.svg';
  const hasDisplayPricing =
    !isEmpty(plan.displayDiscount?.discountWithFrequency) ||
    !isEmpty(plan.displayPricing);

  const duoBundleIdentifiers = [PSEUDO_2P, DUO_BASIC, DUO_PREMIUM];
  const isDuoBundlePlan = duoBundleIdentifiers.includes(plan.identifier);
  const duoBundleLogoURL = '/static/images/disney-hulu-bundle-logo.svg';

  const shouldShowTrioBundleLogo =
    isDisneySuperBundle &&
    !isSashLiveDisneyBundle(plan.identifier) &&
    !isDuoBundlePlan;

  /**
   * Builds the price content for a pricing element for
   * a plan on Mobile view
   * @param pricingText {string} plan price
   * @param index {number} element index in the pricing array
   * @returns {JSX.Element}
   */
  const pricingMapper = (pricingText, index) => {
    const showThen = hasTrial || index > 0;

    if (planDiscountDescription) {
      return (
        <div className="plan__price" key={index}>
          {planDiscountDescription}
        </div>
      );
    }

    return (
      <div className="plan__price" key={index}>
        {showThen && 'then '}
        {!showThen && `Get ${name} for `}
        {pricingText}
      </div>
    );
  };

  return (
    <>
      <Panel
        id={`plan-${plan.id}`}
        className={classNames(
          'plan scrolling',
          sanitize('plan', plan.name.toLowerCase()),
          planClass,
          !planDisplayTrial && !planDiscountDescription && 'plan__no-trial',
          isLiveOnly && 'live-only',
          isBundleOffer && 'bundle-offer',
          isPriceZero && 'price-zero',
          isLivePriority && 'live-priority',
          !plan.pricingDisclaimer && 'plan--no-price-disclaimer'
        )}
      >
        <div className="plan__details">
          <div
            className={classNames('plan__details--content', {
              'plan__details--no-badge':
                !badgeDescription && !mobileBadgeDescription,
              'plan__details--no-price-disclaimer': !plan.pricingDisclaimer,
            })}
          >
            {(badgeDescription || mobileBadgeDescription) && (
              <Badge
                badgeType={badgeType}
                description={badgeDescription}
                mobileDescription={mobileBadgeDescription}
              />
            )}
            {!hideTitle && (
              <h3
                className={`plan__title ${
                  isDisneySuperBundle || isDuoBundlePlan
                    ? 'plan__title__long'
                    : ''
                }`}
              >
                {name}
              </h3>
            )}
            {isDuoBundlePlan && (
              <div className="plan__logo plan__logo-title">
                <img src={duoBundleLogoURL} alt="Hulu and Disney Plus logos" />
              </div>
            )}
            {shouldShowTrioBundleLogo && (
              <div className="plan__logo plan__logo-title">
                <img
                  src={bundleLogoColorURL}
                  alt="Hulu, Disney Plus and ESPN Plus logos"
                />
              </div>
            )}
            {planDisplayTrial && (
              <div className="plan__offer plan__offer-xp">
                {planDisplayTrial}
              </div>
            )}
            {hasDisplayPricing && (
              <div
                className={classNames('plan__price', 'plan__price-xp', {
                  'plan__price-no-trial': !planDisplayTrial,
                  'plan__price--with-discount':
                    plan.displayDiscount && !planDisplayTrial,
                })}
              >
                {getPrice(plan.displayPricing, plan.displayDiscount)}
              </div>
            )}
            {planDiscountDescription && (
              <div className="plan__discount-description">
                {planDiscountDescription}
              </div>
            )}
            <div
              className={classNames('plan__description', {
                'plan__description--with-discount-or-trial':
                  !planDiscountDescription || !planDisplayTrial,
              })}
            >
              {description}
            </div>
            {isHuluFreeTrialsEnabled &&
              isSashLiveDisneyBundle(plan.identifier) && (
                <div className="plan__cost">
                  {planDisplayTrial && (
                    <div className="plan__offer alt">{planDisplayTrial}</div>
                  )}
                </div>
              )}
            {((!isDisneySuperBundle && !isDuoBundlePlan) ||
              showFlashSalePlan) && (
              <div className="plan__cost">
                {planDisplayTrial && (
                  <div className="plan__offer">{planDisplayTrial}</div>
                )}
                {planAltDisplayTrial && (
                  <div className="plan__offer alt">{planAltDisplayTrial}</div>
                )}
                {planDisplayDiscount && (
                  <div className="plan__offer plan__discount">
                    {planDisplayDiscount}
                  </div>
                )}
                {plan.displayPricing.map(pricingMapper)}
              </div>
            )}
            {plan.pricingDisclaimer && (
              <div className="plan__disclaimer pricing__disclaimer">
                {plan.pricingDisclaimer}
              </div>
            )}
            {isBundleOffer && hasBundles(plan) && (
              <div className="plan__bundle">
                <img
                  className="plan__bundle--logo"
                  src={bundleLogoColorURL}
                  alt="Disney Plus and ESPN Plus logos"
                />
                <CheckBoxField
                  model={`plan-${plan.id}-bundle`}
                  id={`plan-${plan.id}-bundle`}
                  text="Bundle with Disney+ and ESPN+ for $7/mo more"
                  label="Bundle with Disney+ and ESPN+ for $7 per month more"
                />
                <div className="plan__bundle--disclaimer">
                  No free trial if subscribing to the bundle.
                </div>
              </div>
            )}
            {!shouldShowDetails && (
              <input
                type="radio"
                id={plan.id}
                name="selectActivePlan"
                checked={carouselActiveKey === plan.identifier}
                readOnly
              />
            )}
            {shouldShowDetails && shouldShowTrioBundleLogo && (
              <div className="plan__logo">
                <img
                  src={bundleLogoColorURL}
                  alt="Hulu, Disney Plus and ESPN Plus logos"
                />
              </div>
            )}
            {shouldShowDetails && isDuoBundlePlan && (
              <div className="plan__logo">
                <img src={duoBundleLogoURL} alt="Hulu and Disney Plus logos" />
              </div>
            )}
            {plan.identifier === SASH && <StudentDiscountLink />}
          </div>
          <div className="plan__cta">
            <button
              className={`button ${buttonClass}`}
              aria-label={`Select ${plan.name} for ${plan.displayPricing}`}
              type="button"
              disabled={!isInteractive}
              onClick={onPlanClick}
              data-testid={`select-button--${plan.identifier}${
                shouldShowDetails ? '-details' : ''
              }`}
            >
              SELECT
            </button>
          </div>

          <div className="plan__features">
            <ul>
              {negativePlanFeatureList &&
                negativePlanFeatureList.map((feature, index) => (
                  <li className="missing" key={index}>
                    {feature}
                  </li>
                ))}
              {positivePlanFeatureList &&
                positivePlanFeatureList.map((feature, index) => (
                  <li className="check" key={index}>
                    {feature}
                  </li>
                ))}
            </ul>
          </div>

          <PlanDisclaimer {...disclaimerProps} />
          {plan.identifier === ADS_LIVE_DISNEY_ESPN && (
            <LiveTvLink handleLinkClick={onLinkPlanClick} />
          )}
        </div>
      </Panel>
      <div className="plan-offcard-terms">
        <PlanDisclaimer {...disclaimerProps} />
      </div>
    </>
  );
};

Plan.defaultProps = {
  shouldShowDetails: false,
  isInteractive: true,
  planClass: '',
  buttonClass: '',
  showStudentDiscount: false,
  mobileBadgeDescription: '',
};

export const PlanPropTypes = PropTypes.shape({
  badge: PropTypes.string,
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  displayPricing: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayTrial: PropTypes.string,
  displayDiscount: PropTypes.shape({
    discount: PropTypes.object,
    duration: PropTypes.object,
    discountWithFrequency: PropTypes.string,
    priceAfterDiscount: PropTypes.string,
    priceWithDuration: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  identifier: PropTypes.string.isRequired,
  isDisneySuperBundle: PropTypes.bool,
  pricingDisclaimer: PropTypes.string,
  pricing: PropTypes.arrayOf(
    PropTypes.shape({
      discount: PropTypes.object,
    })
  ),
  trial: PropTypes.shape({
    length: PropTypes.number,
  }),
});

Plan.propTypes = {
  plan: PlanPropTypes.isRequired,
  onPlanClick: PropTypes.func.isRequired,
  onLinkPlanClick: PropTypes.func,
  isLiveOnly: PropTypes.bool,
  isBundleOffer: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isPriceZero: PropTypes.bool,
  isLivePriority: PropTypes.bool,
  hasBundles: PropTypes.func,
  showFlashSalePlan: PropTypes.bool,
  hideTitle: PropTypes.bool,
  negativePlanFeatureList: PropTypes.arrayOf(PropTypes.string.isRequired),
  positivePlanFeatureList: PropTypes.arrayOf(PropTypes.string.isRequired),
  hideBadge: PropTypes.bool,
  planClass: PropTypes.string,
  buttonClass: PropTypes.string,
  carouselActiveKey: PropTypes.string,
  shouldShowDetails: PropTypes.bool,
  isInteractive: PropTypes.bool,
  hasTrial: PropTypes.bool.isRequired,
  planAltDisplayTrial: PropTypes.string,
  planDisplayTrial: PropTypes.string,
  planDisplayDiscount: PropTypes.string,
  badgeDescription: PropTypes.string,
  badgeType: PropTypes.string,
  disclaimerProps: PlanDisclaimerPropTypes.isRequired,
  description: PropTypes.node,
  name: PropTypes.node,
  showStudentDiscount: PropTypes.bool,
  planDiscountDescription: PropTypes.string,
  mobileBadgeDescription: PropTypes.string,
  isDisneySuperBundle: PropTypes.bool,
  isHuluFreeTrialsEnabled: PropTypes.bool,
};

export default Plan;
